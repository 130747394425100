const initGA = (gaid) => {
  let s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = `https://www.googletagmanager.com/gtag/js?id=${gaid}`;
  document.body.appendChild(s);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', `${gaid}`, {
    'cookie_domain': '.' + window.location.hostname.replace('www.', '')
  });

  function createFunctionWithTimeout(callback, optTimeout) {
    var called = false;

    function fn() {
      if (! called) {
        called = true;
        callback();
      }
    }
    setTimeout(fn, optTimeout || 1000);
    return fn;
  }

  // GA Tracking Code
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    },i[r].l = 1 * new Date();

    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)

  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

  ga('create', `${gaid}`, 'auto');
  ga('require', 'linker');
  ga("require", "displayfeatures");
  ga('send', 'pageview', {
    hitCallback: createFunctionWithTimeout(function () {
      if (typeof window.CallbackFunction === "function") {
        CallbackFunction();
      }
    })
  });
}

export default initGA;
