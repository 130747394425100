/*
 * @Date: 2023-02-27 03:01:31
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 23:52:07
 * @FilePath: \F1-M3-QRP-CODE\config\Host.config.js
 */
let Config = {};

export const STAGE_HOST_URL_API  = 'https://f1-qrp-stage-awstk.fubnb.com';
export const HOST_URL_API        = 'https://f1-qrp-live-awstk.fubnb.com';
export const SL_HOST_URL_API     = 'https://f1-qrp-sl-awstk.fubnb.com';

export const STAGE_ENPOINT_URL = 'https://qrpstagingfun88.gamealiyun.com';
export const ENPOINT_URL = 'https://qrpfun88.gamealiyun.com';


// 判断环境
if ( typeof global.location !== 'undefined' ){
	let LocalHost = global.location.host;
	let isHttps = 'https:' === document.location.protocol;
	let isLocal = Boolean(
        [
            "localhost:8889",
        ].find((v) => global.location.host.includes(v))
    );
	let SLAPI = Boolean(
		[
			'qrpm3sl',
			// 'localhost:8889'
		].find((v)=>global.location.href.includes(v))
	)
	
	// 测试环境
	let StagingAPI = Boolean(
		[
			'qrpm3stag',
			'127.0.0.1:8889',
			'localhost:8889'
		].find((v)=>global.location.href.includes(v))
	)
	if( StagingAPI ){
		
		Config = {
			HostApi   : STAGE_HOST_URL_API,
			LocalHost : '',
			CMSURL    : isLocal ? `https://cache.p5stag.fun88.biz` : `https://cache.${LocalHost}`, 
			ENDPOINT  : STAGE_ENPOINT_URL
		}
	}else if(SLAPI){
		// 判断是否在master 分支
		
		Config = {
			HostApi   : SL_HOST_URL_API,
			LocalHost : '',
			CMSURL    : isLocal ? `https://cache.p5sl.fun88.biz` : `https://cache.${LocalHost}`, 
			ENDPOINT  : ENPOINT_URL
		}
	}else{
		// 判断是否在SL 
		// if ( LocalHost === '' )
		
		Config = {
			/* 灰度地址 */
			HostApi: HOST_URL_API,
			LocalHost: (isHttps ? 'https://' : 'http://') + LocalHost + '/',
			CMSURL: `https://cache.${(global.location.host).replace("www.", "")}`
		};
		
	}
		

} 

export default { Config };
