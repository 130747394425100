export function prependCMSURL4CMSImageValue(CMSURL, imgsrc) {
    let imgsrcLower = typeof imgsrc==='string' ? imgsrc.toLowerCase() : imgsrc;
    return imgsrcLower.indexOf('https://')===0 || imgsrcLower.indexOf('http://')===0 ? imgsrc : (CMSURL + imgsrc);
}

// copy from f1m1desk
export const AvifCheckerModule = (function(){
    const SESS_KEY = 'storageIsAvifSupported';

    const isAvifSupportedPromise = () => {
        if (!isAvifNotChecked()) {
            return Promise.resolve(isAvifSupported())
        }
        return new Promise(resolve => {
            const img = new Image();
            img.src = location.protocol + '//' + location.host + '/static/images/avifTester.avif';
            img.onload = () => { global.sessionStorage?.setItem(SESS_KEY, 'Y'); resolve(true); }
            img.onerror = () => { global.sessionStorage?.setItem(SESS_KEY, 'N'); resolve(false); }
        });
    };

    const isAvifSupported = () => {
        return global.sessionStorage?.getItem(SESS_KEY) === 'Y'
    }
    const isAvifNotSupported = () => {
        return global.sessionStorage?.getItem(SESS_KEY) === 'N'
    }
    const isAvifNotChecked = () => {
        return !isAvifSupported() && !isAvifNotSupported();
    }

    return {
        isAvifSupportedPromise,
    };
})();

//是否支持webp 格式 (copy from f1m1desk)
export function isWebPSupported() {
    if (typeof window === "undefined") return false;

    const elem = document.createElement("canvas");

    if (!!(elem.getContext && elem.getContext("2d"))) {
        // was able or not to get WebP representation
        return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    }

    return false;
}